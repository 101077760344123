import React, { useState, useEffect } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import "./preview.css";
import {
  savePostContent,
  setDescription,
  setTitle,
  setCoverPhotoUrl,
} from "../Slices/Post.slice";
import { useDispatch, useSelector } from "react-redux";
import EditorTextParser from "../blogPost/Editor/Editor-parser/Editor_parser";
import { BiX } from "react-icons/bi";
import CoverIcon from "../Assets/Blog/Image.svg";
import axios from "axios";
import { DataProvider } from "./GetData";
import { useData } from "../Preview/GetData";
import { useEditorData } from "../blogPost/Editor/Editor";
import {
  setmetaDescription,
  setmetaTitle,
  resetState,
} from "../Slices/Post.slice";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { farmsellApi } from "../../Api/famsellAPi";
import BottonLoader from "../../utils/BottonLoader";
import useError from "../../Hooks/useError";
import ErrorComponent from "../../utils/ErrorComponent";
export function Preview({
  rawdata,
  setPreview,
  setData,
  postId,
  updatedPost,
  categories,
}) {
  console.log(postId);
  const [post_loading, setPostLoading] = useState(false);
  const { userError, setUserError } = useError();
  const [description1, setdescription] = useState("");
  const username = useSelector((state) => state.users.user?.user?.name);
  const data = JSON.stringify({ rawdata });
  const titlep = useSelector((state) => state.posts.title);
  const description = useSelector((state) => state.posts.description);

  const userid = useSelector((state) => state.users.user?.user?.id);
  const coverImg = useSelector((state) => state.posts.coverPhotoUrl);
  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const postsdata = useSelector((state) => state.posts?.singlepost);
  const titl = postsdata ? postsdata?.title : null;
  const postData = postsdata ? JSON.parse(postsdata.data) : null;
  const metaTitle = useSelector((state) => state.posts?.metaTitle);
  const metaDescription = useSelector((state) => state?.posts.metaDescription);
  const updatePost = useSelector((state) => state.posts.updatePost);
  const title = titl ? titl : titlep;
  //     console.log(title)
  //     console.log(postData)
  // console.log({
  //     categories
  // })
  const navigate = useNavigate();

  const stingCategories = JSON.stringify({ categories });
  console.log(stingCategories);

  const UpdatePost = async () => {
    try {
      setPostLoading(true);
      const response = await farmsellApi.patch(
        `/posts/update/${postId}/`,
        {
          title,
          data,
          description,
          coverImgUrl: coverImg,
          categories: stingCategories,
          metaTitle,
          metaDescription,
          status: "pending",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(savePostContent(response));
      setPostLoading(false);
      navigate("/post");
    } catch (error) {
      setUserError(error.response.data.error.message);
      setPostLoading(false);
    }
  };

  const handleDraft = async (e) => {
    e.preventDefault();
    try {
      setPostLoading(true);
      const response = await farmsellApi.post(
        `/posts/${userid}/`,
        {
          title,
          data,
          description,
          coverImgUrl: coverImg,
          categories: stingCategories,
          metaTitle,
          metaDescription,
          status: "Draft",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(savePostContent(response));
      setPostLoading(false);
      navigate("/post");
      dispatch(resetState());
    } catch (error) {
      setUserError(error.response.data.error.message);
      setPostLoading(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    if (updatePost) {
      UpdatePost(postId);
    } else {
      try {
        setPostLoading(true);
        const response = await farmsellApi.post(
          `/posts/${userid}/`,
          {
            title,
            data,
            description,
            coverImgUrl: coverImg,
            categories: stingCategories,
            status: "pending",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch(savePostContent(response));

        // Clear state data after successful post
        dispatch(setCoverPhotoUrl());
        setData();
        dispatch(resetState());
        setPostLoading(false);
        // Navigate only when the post has been successfully posted
        navigate("/post");
      } catch (error) {
        setUserError(error.response.data.error.message);
        setPostLoading(false);
      }
    }
  };
  const handledescription = (e) => {
    setdescription(e.target.value);
    dispatch(setDescription(description1));
  };

//   console.log({
//     postData,
//     updatePost,
//     rawdata,
//     titl,
//     title,
//     userError,
//   });

  return (
    <div className="preview-wrapper">
      <div className="preview-close">
        <div
          className="preview-close-body"
          onClick={() => setPreview((current) => !current)}
        >
          <BiX />
          <p className="close-preview-p">Close preview</p>
        </div>
      </div>
      <div className="preview-header">
        <h3 className="preview-h3">Story Preview</h3>
        <p className="preview-header-p">
          Include a high-quality image in your story to make it more inviting to
          readers. This will act as a cover image for your story
        </p>
      </div>
      <div className="section-preview">
        <div className="preview-left">
          <div className="preview-cover">
            {postsdata && postsdata.coverImgUrl && updatePost ? (
              <img
                src={postsdata.coverImgUrl}
                alt="Cover"
                className="uploaded-cover-photo"
                style={{ objectFit: "contain",maxWidth:"200px" }}
              />
            ) : coverImg ? (
              <img
                src={coverImg}
                alt="Cover"
                className="uploaded-cover-photo"
                style={{
                  height: "100%",
                  maxWidth: "200px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                src={CoverIcon}
                alt="Cover"
                className="uploaded-cover-photo"
                style={{ objectFit: "contain" }}
              />
            )}
          </div>
          <div class="input-container-preview">
            {postData ? (
              <input
                type="text"
                placeholder="Write a preview title"
                onChange={(e) => setTitle(e.target.value)}
                value={titl ? titl : null}
              />
            ) : (
              <input
                type="text"
                placeholder="Write a preview title"
                onChange={(e) => setTitle(e.target.value)}
                value={title ? title : null}
              />
            )}
          </div>

          <div class="input-container-preview">
            <input
              type="text"
              placeholder="Write a preview description"
              onChange={handledescription}
            />
          </div>
        </div>
        <div className="preview-right">
          <div className="preview-right-header">
            <p className="preview-right-p">
              Publishing as:{" "}
              <span className="preview-right-p-span">
                {username ? username : null}
              </span>
            </p>
          </div>
          <div className="input-container-preview-right-parent">
            <div class="input-container-preview-right">
              <label for="Meta Title">Meta Title</label>
              <input
                type="text"
                id="Meta Title"
                onChange={(e) => dispatch(setmetaTitle(e.target.value))}
              />
            </div>

            <div class="input-container-preview-right">
              <label for="Meta Description">Meta Description</label>
              <input
                type="text"
                id="Meta Description"
                onChange={(e) => dispatch(setmetaDescription(e.target.value))}
              />
            </div>
          </div>
          <div className="preview-publish-now">
            <button onClick={postData ? UpdatePost : handleClick}>
              {post_loading ? <BottonLoader /> : "Publish Now"}
            </button>
            <p className="preview-schedule" onClick={handleDraft}>
              {post_loading ? <BottonLoader /> : "Schedule for later"}
            </p>
          </div>
          <div className="preview-error">
            {userError ? <ErrorComponent error={userError} /> : null}
          </div>
        </div>
      </div>
      <div className="preview-content-post">
        <div className="post-preview-post-header">
          <h3>This is how your post will be displayed to the reader</h3>
        </div>
        <div className="text-div">
          {/* <h3>{title?title:"No title"}</h3> */}
          {postData !== null || rawdata !== null ? (
            <>
              {postData?.rawdata && (
                <EditorTextParser data={postData?.rawdata} />
              )}
              {!postData?.rawdata && rawdata && (
                <EditorTextParser data={rawdata} />
              )}
            </>
          ) : (
            updatePost && <EditorTextParser data={rawdata} />
          )}
        </div>
      </div>
    </div>
  );
}
