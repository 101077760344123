import React, { useState } from "react";
import ErrorComponent from "../../utils/ErrorComponent";
import "../UserProfile/user.css";
import axios from "axios";
import userPic from "../Assets/Blog/Ellipse 1.svg";
import Edit from "../Assets/Blog/Edit.svg";
import Editp from "../Assets/Blog/edit p.svg";
import BlogNav from "../BlogNav";
import { setProfilepic } from "../Slices/User.slice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Loader from "../../utils/Loader";
import BottonLoader from "../../utils/BottonLoader";
import { farmsellApi } from "../../Api/famsellAPi";
import OuterNavBar from "../../Pages/OuterNavBar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from "react-router";
export default function Resetpassword() {
  const profilepic = useSelector((state) => state.users.userProfilePic);
const [buttonloading,setLoading] =useState(false)
  const [editableFields, setEditableFields] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [Error, setError] = useState("");
  const notify = () => toast("Success!")
  const loading = useSelector((state) => state.users.loading);
  console.log(editableFields);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableFields({ ...editableFields, [name]: value });
  };
const navigate = useNavigate()
  console.log(profilepic);
  const token = useParams();
  console.log("token", token.token);
const passwordValidCheck = ()=>{
  
  if (!editableFields.newPassword) {
    setError("Password is required");
    
  } else if (editableFields.newPassword.length < 8) {
    setError("Password should contain at least 8 characters");
    
  } else if (!/[A-Z]/.test(editableFields.newPassword)) {
    setError("Password should contain at least one uppercase letter");
  
  } else if (!/[0-9]/.test(editableFields.newPassword)) {
    setError("Password should contain at least one number");
   
  }
  else if(editableFields.newPassword !== editableFields.confirmPassword ) {
    
    setError("New password and confirm password do not match")
  }
  return

}
  const handleSaveChanges = async () => {
 
    try {
      passwordValidCheck()
      setLoading(true)
      const response = await farmsellApi.put(
        `http://localhost:4000/resetPassword/`,
        { password: editableFields.confirmPassword },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.token,
          },
        }
      );
      setLoading(false)
     notify()
     
     setTimeout(() => {
      navigate("/");  // Navigate after a short delay
    }, 2000); 
      
      // If needed, dispatch an action to update local state with the changes
    } catch (error) {
      console.error("Error while saving changes:", error);
      setLoading(false)
    }
  };
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  console.log("Error",Error)
  return (
    <div className="BlogWrapper">
      <div>
        <OuterNavBar />
      </div>
      <div className="user-wrapper">
        <div className="input-container">
          <div className="item-colum">
            <div className="input-wrapper">
              <div>
                <h3>Reset Your Password</h3>
              </div>
              <p></p>
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={editableFields.newPassword}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-wrapper">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={editableFields.confirmPassword}
                onChange={handleInputChange}
              />
            </div>
            <button onClick={handleSaveChanges}>

            {
                buttonloading ?<BottonLoader/> :"Save"
              }
            </button>
            <div>

            {Error && <ErrorComponent error={Error} />}
            </div>
          </div>

        </div>
        
      </div>
    <ToastContainer/>
    </div>
  );
}
