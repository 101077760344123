import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import Cookies from 'js-cookie';
// Component to handle authentication routes
export  const AuthenticationRoute = () => {
    const token = Cookies.get('token');
  const location = useLocation();

  // Check if the token is valid (not null, undefined, or "undefined")
  return token !== null && token !== undefined && token !== "undefined" ? (
    <Outlet /> // If authenticated, render the child routes
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace /> // If not authenticated, redirect to login
  );
};

// Component to handle public routes with an optional prop isPublic
export const PublicRoutes = ({ isPublic }) => {

  const token = Cookies.get('token');
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect hook to handle navigation based on authentication status
  useEffect(() => {
    const from = location.state?.from?.pathname || "/signin";
    if (token) {
      navigate(from, { replace: true }); // If authenticated, navigate to the original location or a default route
    }
  }, [token, location.state, navigate]);

  // Check if the token is invalid or the route is public
  if (token === null || token === undefined || (token === "" && isPublic)) {
    return <Outlet />; // If the route is public or the token is invalid, render the child routes
  }

  return <Outlet />; // Always render the child routes (this might be redundant)
};
