import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { postUser, getUser, authenticateUser } from '../Slices/User.slice';
import createHero from '../Assets/Blog/OBJECTS.svg';
import createLogo from '../Assets/Blog/Layer 2.svg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import userIcon from '../Assets/Blog/account_circle.svg';
import mainIcon from '../Assets/Blog/mail.svg';
import passwordicon from '../Assets/Blog/key.svg';
import { FaGoogle, FaFacebook, FaUser, FaEnvelope, FaLock } from 'react-icons/fa';
import googleIcon from '../Assets/Blog/🦆 icon _google_.svg'
import facebookIcon from '../Assets/Blog/🦆 icon _Facebook v1_.svg'
import './Create.css';
import { Link, json, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { farmsellApi } from '../../Api/famsellAPi';
import Cookies from 'js-cookie';
import useError from '../../Hooks/useError';
import ErrorComponent from '../../utils/ErrorComponent';
import BottonLoader from '../../utils/BottonLoader';
import { setUserLoading } from '../Slices/User.slice';
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
export default function CreateAccount() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const [accountCreated, setAccountCreated] = useState(false);
  const { setUserError, userError } = useError()
  const userloading = useSelector(state => state.users.userLoading);
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
    role: ''
  })

  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate function
  const isAuthenticated = useSelector(state => state.users.isAuthenticated);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (e) => {
    setValues(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }), async () => {

      console.log(values); // Log the updated state inside the callback function
    });
  };


  const postData = async () => {
    try {
      const response = await farmsellApi.post('/signup', values, {
        headers: {
          "Content-Type": "application/json"
        }
      })

      const { token } = response.data;

      // Set the token in the local storage
      Cookies.set('token', token, { expires: 1 })
      dispatch(getUser())
      dispatch(authenticateUser(response));
      dispatch(setUserLoading(false))
    } catch (error) {
      setError('Error Creating account. Please try again.');
      setUserError(error.response.data.error.message)

      console.error('Error while posting data:', error);
    }
  };

  console.log(error)

  if (isAuthenticated) {
    navigate('/post');

  }
  const onSubmit = async (e) => {
    if (!values.name) {
      setError("Please Enter Your Name!")
    } else if (!values.email) {
      setError("Please Enter Your email!")
    }
    console.log(values)
    dispatch(setUserLoading(true))
    postData()
    dispatch(setUserLoading(false))
    // Validate form data before submitting
    if (!validateForm()) {
      return;
    }

    try {
      //   const url = 'http://localhost:4000/signup'
      //  await fetch(url, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json"
      //     },

      //     body: values
      //   })


      // Handle success

    } catch (error) {
      // Handle error
      console.error('Error creating account:', error);
    }
  };

  const validateForm = () => {
    // Perform form validation
    // Return true if form is valid, false otherwise
    // You can perform validation on fullName, email, and password here
    // Return false if any validation fails
    return true; // Placeholder return value
  };


  const googleApiKey = process.env.REACT_APP_GOOGLEAPIKEY;
  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (googleUser) => {
      console.log(googleUser);
      const idToken = googleUser.access_token;
      console.log("id token", idToken);
      const response = await axios.get(
        googleApiKey,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      const data = response.data;
      console.log(data);
      await farmsellApi
        .post("/google-auth", {
          name: data.name,
          email: data.email,
          picture: data.picture,
        }) // Replace 'your_client_id' with your actual Google client ID
        .then((response) => {
          const { token } = response.data;
          
          
          Cookies.set("token", token, { expires: 1 });
          dispatch(getUser());
          dispatch(authenticateUser(response));
        })
        .catch((error) => console.error("Error:", error));
    },

    onFailure: (error) => {
      console.error("Login Failed:", error);
    },
  });

  const handleFacebookSignIn = () => {
    // Implement Facebook sign-in functionality
  };
  console.log(values)
  return (
    <div className="create-wraper">
      <div className="create-left">
        <img src={createHero} alt="Create Hero" />
      </div>
      <div className="create-right">
        <div className="create-right-wraper">
          <div className="create-nav">
            <img src={createLogo} alt="Create Logo" />
            <button className="create-nav-blog" onClick={() => navigate('/blogs')}>Blog</button>
          </div>
          <div className="create-h3">
            <h3>Create Account</h3>
          </div>

          <div className="create-account-form-container">
         
            <div className="sign-in-options">
            <div className="create-divide">
            <h3 className='create-OR'>- OR -</h3>
          </div>
              {/* <button className="google-signin" onClick={handleGoogleSignIn}>
                <img src={googleIcon} alt="Google Icon" />
                Sign in with Google
              </button> */}
              
            </div>
            <form method="post" onSubmit={handleSubmit(onSubmit)} className="create-account-form">
              <div className="form-group">
                <img src={userIcon} alt="User Icon" />
                <input
                  type="text"
                  id="name"
                  placeholder="Full Name"
                  {...register('name', { required: true })}
                  onChange={handleChange}
                />

              </div>
              <div className="form-group">
                <img src={mainIcon} alt="Main Icon" />
                <input
                  type="email"
                  id="email"
                  placeholder="Email Address"
                  {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                  onChange={handleChange}
                />

              </div>
              <div className="form-group">
                <img src={passwordicon} alt="Password Icon" />
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  required
                  {...register('password', { required: true })}
                  onChange={handleChange}
                />

                <span className="password-toggle" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash color="#9D9D9D" /> : <FaEye color="#9D9D9D" />}
                </span>
              </div>
              <button type="submit" className="submit-btn">{userloading ? <BottonLoader /> : 'Create Account'}</button>
              {userError && <ErrorComponent error={userError} />}


            </form>

          </div>
          <div className="create-option">
            <p>Already have an account? <Link to="/signin"><span>Log in</span></Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}
