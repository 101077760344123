import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../Components/Slices/User.slice';

const withUserFetch = (WrappedComponent) => {
  const HOC = (props) => {
    const dispatch = useDispatch();

    
    useEffect(()=>{
    
    dispatch(getUser())
    },[dispatch])

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withUserFetch;
