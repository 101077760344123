import React,{useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import './../Components/navbar.css'
import createLogo from '../Components/Assets/Blog/Layer 2.svg'
// import userPic from './Assets/Blog/Ellipse 1.svg'
// import NavProfile from './Assets/Blog/profile.svg'
// import navBlog from './Assets/Blog/Blog (1).svg'
// import navStat from './Assets/Blog/Stats.svg'
// import navSignout from './Assets/Blog/log out.svg'
// import { SlArrowDown } from "react-icons/sl";
// import { SlArrowUp } from "react-icons/sl";
import { Link ,useNavigate} from 'react-router-dom';
// import { useData } from './Preview/GetData';
// import { logoutUser } from './Slices/User.slice';

export default function OuterNavBar() {
//   const {data} = useData()
//   console.log(data)
//     const [showProfileMenu, setShowProfileMenu] = useState(false);
//     const profilepic = useSelector(state => state.users.user?.UserPic)
//     const username = useSelector(state => state.users?.user?.name);
//     const currentUser = useSelector(state => state.users?.user?.user);
    const navigate= useNavigate()
//     const dispatch = useDispatch()
//     const toggleProfileMenu = () => {
//       setShowProfileMenu(!showProfileMenu);
//     };
   
  return (
    <>
    <div className='blog-nav-wrapper' style={{margin:0}}>
     <div className='nav-logo' >
        <img src={createLogo} onClick={()=>{navigate('/') }}/>
       
     </div>
     <div   className='outer-nav-link'>

     <button >Take me to Farmsell</button>
     </div>
     
     
    </div>
    <div className='divider' style={{marginBottom:"5%"}}></div>
    </>
    
  )
}
