import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posts: [],
  postsLoading: true,
  categories: [],
  categoriesLoading: false,
  recentPosts: [],
  rentPostsLoading: true,
  postDetails: null,
  AllPosts:[]
};
const fetchpostSlice = createSlice({
  name: "fetchposts",
  initialState,
  reducers: {
    setPostDetails: (state, action) => {
      state.postDetails = action.payload;
    },
    setPostsData: (state, action) => {
      state.posts = action.payload;
    },
    setAllPosts: (state, action) => {
      state.AllPosts = action.payload;
    },
    setRecentPosts: (state, action) => {
      state.recentPosts = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setPostsLoading: (state, action) => {
      state.postsLoading = action.payload;
    },
    setrentPostsLoading: (state, action) => {
      state.rentPostsLoading = action.payload;
    },
    setcategoriesLoading: (state, action) => {
      state.categoriesLoading = action.payload;
    },
  },
});

export const {
  setPostsData,
  setRecentPosts,
  setCategories,
  setPostsLoading,
  setrentPostsLoading,
  setcategoriesLoading,
  setPostDetails,
  setAllPosts
} = fetchpostSlice.actions;
export default fetchpostSlice.reducer;
