// Post.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Posts/Post.css';
import { CiSearch } from "react-icons/ci";
import PostCard from "../Posts/PostCard";
import noPublished from '../Assets/Blog/Blog.svg'
import BlogNav from '../BlogNav';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts, fetchPublised, fetchDraft, deletePost, fetchTrashPosts, setPostLoading } from '../Slices/Post.slice';
import Loader from '../../utils/Loader';
import { farmsellApi } from '../../Api/famsellAPi';
import { logoutUser } from '../Slices/User.slice';
const ListItem = ({ item, isActive, onClick, count }) => {
  return (
    <div className={`list-item ${isActive ? 'active' : ''}`} onClick={onClick}>
      {item} ({count})
    </div>
  );
};

const Trash = ({ trash, onDelete, onRestore }) => {
  return (
    <div className="trash-posts">
      {trash.length === 0 ? (
        <div className='no-published-wrapper'>
        <div className='no-img'>
          <img src={noPublished} alt='no published content' />
        </div>
        <div className='no-text'>
          <p className='no-p'>
            Opps! Looks like you haven’t trashed any Post yet, Check in your <span className='no-drafts'>published </span> or write
            a new blog
          </p>
        </div>
      </div>
      ) : (
        <div>
          {trash && trash.map((post) => (
            <PostCard
              key={post.id}
              id={post.id}
              title={post.title}
              description={post.description}
              createdAt={post.createdAt}
              onDelete={() => onDelete(post.id, 'trash')}
              onRestore={()=>onRestore(post.id)} // Pass onRestore function directly
              postType="trash"
            />
          ))}
        </div>
      )}
    </div>
  );
};

const Published = ({ posts, onDelete, id,onRestore }) => {
  const navigate = useNavigate()
  console.log(id)
  const handleView = (postId) => {
    navigate(`/post/${postId}`);
  };

  return (
    <div className="published-posts" >
      {posts.length === 0 ? (
        <div className='no-published-wrapper'>
          <div className='no-img'>
            <img src={noPublished} alt='no published content' />
          </div>
          <div className='no-text'>
            <p className='no-p'>
              Opps! Looks like you haven’t published any public blogs yet, Check in your <span className='no-drafts'>drafts</span> or write
              a new blog
            </p>
          </div>
        </div>
      ) : (
        <div>
          {posts && posts.map(post => (
            <PostCard
              key={post.id}
              id={post.id}
              title={post.title}
              description={post.description}
              createdAt={post.createdAt}
              //onEdit={() => handleEdit(post.id)}
              onDelete={() => onDelete(post.id, 'published')}
              postType="published"
            />

          ))
          }

        </div>
      )}

    </div>
  );
};

const Drafts = ({ drafts, onDelete }) => {
  const handleEdit = (postId) => {
    console.log(`Editing draft ${postId}`);
  };

  return (
    <div className="draft-posts">

      {
      drafts.length ===0 ?(
        <div className='no-published-wrapper'>
          <div className='no-img'>
            <img src={noPublished} alt='no published content' />
          </div>
          <div className='no-text'>
            <p className='no-p'>
              Opps! Looks like you have no drafts posts, Check in your <span className='no-drafts'>published</span> or write
              a new blog
            </p>
          </div>
        </div>
      ):
      
      drafts.map((draft) => (
        <PostCard
          key={draft.id}
          id={draft.id}
          title={draft.title}
          description={draft.description}
          createdAt={draft.createdAt}
          onEdit={() => handleEdit(draft.id)}
          onDelete={() => onDelete(draft.id, 'draft')}
          postType="draft"
        />
      ))}
    </div>
  );
};

export default function Post() {
  const [activeComponent, setActiveComponent] = useState('Published');
  const [drafts, setDrafts] = useState([]);
  const [published, setPublished] = useState([]);
  const [trash, setTrash] = useState([]);
  const [query, setQuery] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch()
  const userId = useSelector(state => state.users.user?.user?.id ||state.users?.user?.data?.id);
  const CurrentUser = useSelector(state => state.users?.user);
  const loading = useSelector(state => state.users.loading);
 const [PostLoading,setLoading] = useState(false)

  const postsdata = useSelector(state => state.posts?.user_posts?.post) || [];
  const {totalPages} =useSelector(state => state.posts?.user_posts) || []
  const PublishedPosts = useSelector(state => state.posts.PublisedPosts)
  const draftPosts = useSelector(state => state.posts.DraftPosts)
  const trashPosts = useSelector(state => state.posts.TrashPosts)
  const [page, setCurrentPage] = useState(1); // State for current page
  const postsPerPage = 4;
  

  
  useEffect(() => {
    console.log("Post is fetched")
    dispatch(fetchPublised(userId))
    dispatch(fetchPosts({userId,page}))
    dispatch(fetchDraft(userId))
    dispatch(fetchTrashPosts(userId))
    
  }, [userId,dispatch,page]);
  const filteredPosts = postsdata.filter(post => post.title.toLowerCase().includes(query.toLowerCase()));
  const filtedDafts = draftPosts.filter(e => e.title.toLowerCase().includes(query.toLowerCase()))

  
  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  if (filtedDafts != null && filteredPosts != null) {
    dispatch(setPostLoading(false))
  }
console.log({
  filteredPosts
})
  const handleDelete = async (id, type) => {
   setLoading(true)
    dispatch(deletePost({ id: id, type }));
    dispatch(fetchPosts(userId))
    setLoading(false)
    window.location.reload()
  };
  const handleEdit = (postId) => {
    console.log("editing")
  }

  const handleRestore = (id) => {
    setLoading(true)
    try {
      const response = farmsellApi.put(`/api/posts/restore/${id}`)
      console.log(response)
      setLoading(false)
      window.location.reload()
    } catch (error) {
      console.log("restore  post", error)
    }
  };


  const categories = ['Drafts', 'Published', 'Trash'];


  const handleChange = (e) => {
    setQuery(e.target.value);

  };

const deletePostPermanently = (id)=>{
  const response = farmsellApi.delete(`/api/posts/delete/${id}`)
  window.location.reload()
}


  const indexOfLastPost = page * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  
  console.log(filtedDafts.id)
  console.log(filteredPosts.id)

if(!CurrentUser){
  
  return <Loader/>
}
console.log({
  totalPages
})
const handlePageChange = (page) => {
  if (page >= 1 && page <= totalPages) {
    setCurrentPage(page);
  }
};
console.log({
  page
})
  return (
    <div className='BlogWrapper'>
      <div><BlogNav /></div>
      <div className='post-wrapper'>
        <div className='post-header'>
          <div><h3 className='post-header-h3'>All your blogs</h3></div>
          <div className='header-button'>
            <Link to="/Blog"> <button className='bg-button'>Write a blog</button></Link>
            {/* <Link to="/"><button className='bg-button1'>Import a blog</button></Link> */}

          </div>
        </div>
        <div className="search-container">
          <input type="text" placeholder="For easy find, enter a key word or a title of what you are looking for." className="search-input" value={query} onChange={handleChange} />
          <button className="search-button">
            <CiSearch />
          </button>
        </div>

        <div className='my-post'>
          <div className='post-items'>
            <div className="categories-post-main">
              {categories.map((category, index) => (
                <ListItem
                  key={index}
                  item={category}
                  isActive={category === activeComponent}
                  onClick={() => handleComponentChange(category)}
                  count={category === 'Drafts' ? filtedDafts.length : category === 'Published' ? filteredPosts.length : trashPosts.length}
                />
              ))}
            </div>
            <div className='card-line1'></div>
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Published' && <Published posts={filteredPosts} onDelete={handleDelete} id={filteredPosts.id} onEdit={handleEdit} />}
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Drafts' && <Drafts drafts={filtedDafts} id={filtedDafts.id} onDelete={handleDelete} />}
            {PostLoading ? <div><Loader /></div> : activeComponent === 'Trash' && <Trash trash={trashPosts} onDelete={deletePostPermanently} onRestore={handleRestore} />} {/* Pass onRestore function */}
          </div>
          <div className='pagination'>
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 1} className='page-button'>
            &larr;
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`page-button ${page === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </button>
          ))}
          <button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} className='page-button'>
            &rarr;
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}
