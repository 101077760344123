import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import BlogNav from '../BlogNav';
import { fetchPostById } from '../Slices/Post.slice';
import EditorTextParser from '../blogPost/Editor/Editor-parser/Editor_parser';
import './Post.css';

export default function Blogpost() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const postsdata = useSelector((state) => state.posts.singlepost);
  const title = postsdata?postsdata.title : null;
  const postData = postsdata ? JSON.parse(postsdata.data) : null;

  useEffect(() => {
    // Fetch post data by ID when component mounts
    dispatch(fetchPostById(id));
  }, [dispatch, id]);

  // Error handling
  if (!postsdata) {
    return <div>Error: Unable to fetch post data</div>;
  }

  return (
    <div className="post-data-wrapper">
      <div>
        <BlogNav />
      </div>
   
      <div className="post-data">
        {postData ? <EditorTextParser data={postData.rawdata} /> : null}
      </div>
    </div>
  );
}
