import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import './Blog.css';
import { setPostDetails, setPostsData } from '../../Components/Slices/Posts.slice';
import { farmsellApi } from '../../Api/famsellAPi';
// import { setLoading } from '../../Components/Slices/Loading.slice';
import { AuthorDetails, RecentPosts, Categories, Tags, ShareButtons } from '../../Features/postFeatures';
import OuterNavBar from '../OuterNavBar';
import Footer from '../Footer';
import EditorTextParser from '../../Components/blogPost/Editor/Editor-parser/Editor_parser';
import userPic from '../../Components/Assets/Blog/Ellipse 1.svg';
import useFetchPosts from '../../Hooks/useFetchPosts';
export default function BlogPostUI() {
  const dispatch = useDispatch();
  const { title } = useParams(); // Get the title from the URL
  const [post, setPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const { fetchPosts } = useFetchPosts('/post/recent', {
    params: {
      
      pageSize: 6,
    }
  })
  const { fetchData } = useFetchPosts('/posts', {
    params: {
      
      pageSize: 6,
    }
  })
  // Selectors
  const BlogPosts = useSelector((state) => state.BlogPosts.posts);
  const recentPosts = useSelector((state) => state.BlogPosts.recentPosts);
  const postDetails = useSelector((state) => state.BlogPosts.postDetails);

  // Format title for fetching
  const formattedTitle = decodeURIComponent(title).replace(/-/g, ' ');
  const formatPostDetails = (postItem) => {
    return {
      key: postItem.id,
      data: JSON.parse(postItem.data)?.rawdata || null,
      description: postItem.description,
      title: postItem.title,
      metaTitle: postItem.metaTitle,
      metadescription: postItem.metadescription,
      Categories: JSON.parse(postItem.categories)?.categories || [],
      coverPhoto: postItem.coverImgUrl,
      profilePhoto: postItem.user.UserPic || userPic,
      username: postItem.user.name,
      Created: dayjs(postItem.createdAt).format('MMMM DD, YYYY'),
    };
  };

  const fetchPostByTitle = async () => {
    try {
      const response = await farmsellApi.get(`/post/${formattedTitle}`);
      dispatch(setPostDetails(formatPostDetails(response.data)));
    } catch (error) {
      console.error("Error fetching post by title:", error);
    }
  };



  const formatRecentPosts = (posts) => {
    return posts.map((post) => ({
      key: post.id,
      data: post.data ? JSON.parse(post.data)?.rawdata : null,
      description: post.description,
      title: post.title,
      metaTitle: post.metaTitle,
      metadescription: post.metadescription,
      Categories: post.categories ? JSON.parse(post.categories)?.categories : [],
      coverPhoto: post.coverImgUrl,
      profilePhoto: post.user?.UserPic || userPic,
      username: post.user?.name || 'Unknown Author',
      Created: dayjs(post.createdAt).format('MMMM DD, YYYY'),
    }));
  };

  useEffect(() => {
   
      // dispatch(setLoading(true));
      
      try {
        Promise.all([
          fetchPostByTitle(),
          fetchPosts(), // Assuming fetchPosts is imported and fetches recent posts
          fetchData(),  // Assuming fetchData is imported and fetches all posts
        ]);
        if (recentPosts.length > 0) {
          setPosts(formatRecentPosts(recentPosts));
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        // dispatch(setLoading(false));
      }
    

  
  }, [title, postDetails?.title]);  // Adjust dependencies as needed

  return (
    <div className='BlogWrapper'>
      <div className='post-wrapper'>
        <OuterNavBar />
        <div className='post-main-wrapper'>
          <div className='main'>
            <div className='post-cover-image'>
              <img src={postDetails?.coverPhoto} alt='cover-pic'  />
            </div>
            <h3 className='post-title'>{postDetails?.title || "Loading..."}</h3>
            <AuthorDetails
              profilePhoto={postDetails?.profilePhoto}
              posted={postDetails?.Created}
              name={postDetails?.username || "Loading data"}
            />
            <div className='text-div'>
              <img src={postDetails?.coverPhoto} alt='cover' className='post-cover-photo-url'/>
              {postDetails?.data ? <EditorTextParser data={postDetails.data} /> : null}
            </div>
            <div className='divider'></div>
            <ShareButtons title={title} />
          </div>
          <div className='side'>
            <RecentPosts posts={posts} />
            <Categories categories={postDetails?.Categories || []} />
            <Tags />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
