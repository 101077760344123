import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { DataProvider } from './Components/Preview/GetData';
import Store from './Components/Store/Store';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
Store.subscribe(() => console.log(Store.getState()));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}> 
  <HelmetProvider>
  <App />
  </HelmetProvider>
      
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
