import React from "react";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { fetchPosts } from "../Components/Slices/Post.slice";
export function Comment() {
  return (
    <div>
      <h3>Leave a commment</h3>
      <div className="comment-main-row">
        <div className="comment-section-wp-row">
          <label htmlFor="fn">First Name</label>
          <input type="text" id="fn" />
        </div>
        <div className="comment-section-wp-row">
          <label htmlFor="email">Email</label>
          <input type="text" id="email" />
        </div>
      </div>
      <div className="comment-section-wp-colum">
        <label htmlFor="website">Website</label>
        <input type="text" id="website" />
      </div>
      <div className="comment-section-wp-colum">
        <label htmlFor="message">Message</label>
        <textarea type="text" id="message" />
      </div>
      <label className="save-button">
        <input type="checkbox" />
        <span className="Checkmark"></span>
        Save my name, email, and website in this browser for the next time I
        comment.
      </label>
      <div className="submit-wrapper">
        <button className="comment-submit">
          <p className="post-a-comment"> Post a comment</p>
          <p className="icon">
            <FaArrowRightLong />
          </p>
        </button>
      </div>
    </div>
  );
}

export function ShareButtons({ title, icon }) {
  const shareUrl = `https://blog.farmsell.org/post/${title}`;
  const shareTitle = title;

  return (
    <div className="share-container">
      <span className="share-title"> Share</span>
      <div className="social-icons">
        <FacebookShareButton url={shareUrl}>
          <FaFacebookF />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={shareTitle}>
          <FaTwitter />
        </TwitterShareButton>
        <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <FaLinkedinIn />
        </LinkedinShareButton>
        <InstapaperShareButton
          url={shareUrl}
          title={shareTitle}
          className="Demo__some-network__share-button"
        >
          <FaInstagram />
        </InstapaperShareButton>
      </div>
    </div>
  );
}
export function AuthorDetails({ profilePhoto, posted, name }) {
  return (
    <div className="share-container">
      <div className="author-profile-pic">
        <img src={profilePhoto} />
        <p>{name}</p>
      </div>

      <div className="post-details">
        <p className="date-of-post">{posted}</p>
        <span className="green-dot"></span>
        
      </div>
    </div>
  );
}
export function RecentPosts({ posts }) {
  const navigate = useNavigate();
  console.log("recent posts items", posts);
  const handleNavigation = (title) => {
    const urlFriendlyTitle = encodeURIComponent(
      title.toLowerCase().replace(/ /g, "-")
    );
    navigate(`/post/${urlFriendlyTitle}`);
  };
  return (
    <div className="side-bar">
      <h3>Recent Posts</h3>
      {posts
        ? posts.map((item, index) => {
            console.log("items", item);
            return (
              <>
                <div
                  key={index.key}
                  className="recent-card-wrapper"
                  
                >
                    <div className="recent-post-image-wrapper">
                    <img
                    src={item.coverPhoto}
                    alt=""
                    className="recent-post-image"
                  />
                    </div>
                 

                  <div className="">
                    <div className="post-details-recent">
                      <h5>{item.title.length >30?item.title.slice(0,30) + "...":item.title}</h5>
                      <p className="date-of-post">{item.Created}</p>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
              </>
            );
          })
        : null}
    </div>
  );
}
export function Categories({ categorie = [] }) {
  // Ensure categorie is an array
  const categories = Array.isArray(categorie) ? categorie : [];

  return (
    <div className="side-bar">
      <h3 className="blog-categories-h3">Categories</h3>
      <div className="categories-list-row">
        {categories.length > 0 ? (
          categories.map((cat, key) => (
            <div key={key} className="categories-items">
              <p>{cat}</p>
              <span>({cat.length})</span>
            </div>
          ))
        ) : (
          <p>No categories available</p>
        )}
        <div className="divider"></div>
      </div>
    </div>
  );
}
export function Tags() {
  return (
    <div className="side-bar">
      <div className="card-tag-wrapper">
        <h3>Tags</h3>
        <div className="tag-card">
          <p>Design</p>
        </div>
      </div>
    </div>
  );
}
