
import React,{memo, useCallback, useEffect,useState} from 'react'
import { UseDispatch, useDispatch } from 'react-redux'
import { setError } from '../Components/Slices/Post.slice'
import { setUserErrors } from '../Components/Slices/User.slice'
export default function useError() {
const [posterror, setpostError]= useState('')
const [userError, setUserError] = useState('')

const dispatch = useDispatch()
const setposterror = useCallback(()=>{
    dispatch(setError(posterror))
},[posterror])
const setusererror = useCallback(()=>{
    dispatch(setUserErrors(userError))
})
setusererror();
setposterror();
  return {posterror, setpostError,setUserError,userError}
}
