import './footer.css';
import FirstFooter from './firstFooter';
import SecondFooter from './secondFooter';

const Footer = () => {
  return (
    <div className="footerContainer">
      
      <FirstFooter />

      <SecondFooter />
    </div>
  );
};

export default Footer;
