// DataContext.js
import React, { createContext, useState, useContext } from 'react';
import Preview from './Preview';
// Create a context to hold your data
const DataContext = createContext();

// Create a provider component to wrap your component hierarchy
export const DataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [post,setPosts] = useState(null)
  const [rentPosts,setRecentPosts]= useState(null)
console.log(data)
  return (
    <DataContext.Provider value={{ data, setData ,setPosts,setRecentPosts,post,rentPosts}}>
      {children}
     
    </DataContext.Provider>
  );
};


export const useData = () => {
    return useContext(DataContext);
  };
  export const dataContext=()=>DataContext