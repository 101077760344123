import React from 'react'

import './PageLoader.css'
import createLogo from '../Components/Assets/Blog/Layer 2.svg'
export default function PageLoader() {
    return (
        <div className="loader-container">
         <div className='loaderWrapper'>
         <div className="loader-logo">
           <img src={createLogo} alt="logo" />
          </div>
          <div className="loader-bar">
            <div className="progress-bar"></div>
          </div>
         </div>
        </div>
      );
}
