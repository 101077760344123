import React,{useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import './navbar.css'
import createLogo from './Assets/Blog/Layer 2.svg'
import userPic from './Assets/Blog/user.png'
import NavProfile from './Assets/Blog/profile.svg'
import navBlog from './Assets/Blog/Blog (1).svg'
import navStat from './Assets/Blog/Stats.svg'
import navSignout from './Assets/Blog/log out.svg'
import { SlArrowDown } from "react-icons/sl";
import { SlArrowUp } from "react-icons/sl";
import { Link ,useNavigate} from 'react-router-dom';
import { useData } from './Preview/GetData';
import { logoutUser,authenticateUser } from './Slices/User.slice';
import { IoMenuSharp } from "react-icons/io5";
export default function BlogNav() {
  const {data} = useData()
  console.log(data)
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const profilepic = useSelector(state => state.users.user?.UserPic)
    const username = useSelector(state => state.users?.user?.name);
    const currentUser = useSelector(state => state.users?.user?.user ||state.users?.user?.data);
    const navigate= useNavigate()
    const dispatch = useDispatch()
    const toggleProfileMenu = () => {
      setShowProfileMenu(!showProfileMenu);
    };
   
  return (
    <div className='blog-nav-wrapper'>
     <div className='nav-logo' >
        <img src={createLogo} onClick={()=>{navigate('/') ;dispatch(logoutUser(null));dispatch(authenticateUser(null))}}/>
        <button className='create-nav-blog' onClick={()=> navigate('/blogs')}>Blog</button>
     </div>
     <div>
     <div className="navbar-profile" >
     <Link to="/Blog"><button>Write  blog </button></Link>
        
     <img src={currentUser &&currentUser.UserPic !==null ?currentUser.UserPic: userPic} alt="Profile Picture"   style={{  objectFit: 'cover', borderRadius: '50%' }}    />
     <span className='mobile-menue' onClick={toggleProfileMenu}>
      <IoMenuSharp />
      </span>
        <span className='user-nav-name'>{currentUser?.name}</span>
        <span className='nav-arrows' onClick={toggleProfileMenu}>{showProfileMenu ? <SlArrowUp/> : <SlArrowDown/>}</span>
      </div>
      {showProfileMenu && (
        <div className="profile-menu">
          <ul>
            <li onClick={()=>navigate('/user')}>
                <img src={NavProfile} alt='nav profile'/>
                 <p>Profile</p>
                </li>
            <li>

            <img src={navBlog} alt='nav blog'/>
                 <p onClick={()=>navigate('/blogs')}>  Blogs</p>
            </li>
            <li onClick={()=>navigate('/stat')}>
            <img src={navStat} alt='nav statistics'/>
                 <p>Statistics</p>
                </li>
          </ul>
          <button onClick={()=>dispatch(logoutUser(null))}>
            <img src={navSignout} alt='sign out'/>
            <span className='sign-out-button'>Sign out</span>
          </button>
        </div>
      )}
     </div>
    </div>
  )
}
