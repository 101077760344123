// src/store/postSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { farmsellApi } from '../../Api/famsellAPi';
// Define an initial state for the post slice
const initialState = {
  singlepost:null,// Initial content of the post
  htmlContent: '', // Transformed HTML content of the post
  loading: false,
  userLoading: false,
  error: null,
  postError:null,
  title: '',
  postLoading:false,
  coverPhotoUrl: null,
  AllowComments: false,
  updatePost:false,
  description: null,
  categories: [],
  isApproved: false,
  metaTitle:null,
  metaDescription:null,
  user_posts: [],
  PublisedPosts: [],
  TrashPosts: [],
  DraftPosts: [],
  totalPages:null
};



export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async ({ id, type }, { rejectWithValue }) => {
    try {
      
      await farmsellApi.delete(`/api/posts/${id}/`)
      

      return { id, type };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Define a thunk to save post content asynchronously
export const savePostContent = createAsyncThunk('post/savePostContent', async (response) => {
  try {
    // Replace the endpoint with your actual API endpoint

    return response.data; // Assuming response contains any necessary data after saving
  } catch (error) {
    throw new Error('Failed to save post content');
  }
});
export const fetchPublised = createAsyncThunk('post/fetchPublised', async (userId) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await farmsellApi.get(`/${userId}/`);
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});
export const fetchDraft = createAsyncThunk('post/fetchDraft', async (userId) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await farmsellApi.get(`/draft/${userId}/`);
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});

// Define a thunk to fetch posts from the database asynchronously

export const fetchPosts = createAsyncThunk('post/fetchPosts', async ({userId,page}) => {
  try {
console.log("This is the page in the thunk",page,userId)
    // Replace the endpoint with your actual API endpoint
    const response = await farmsellApi.get(`/posts/${userId}`,{
      params:{
        page
      }
    }
      );
      
    
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});
export const fetchTrashPosts = createAsyncThunk('post/fetchTrashPosts', async (userId) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await farmsellApi.get(`/posts/trash/${userId}/`);
    
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to draft posts');
  }
});
export const fetchPostById = createAsyncThunk('post/fetchPostsbyid', async (id) => {
  try {

    // Replace the endpoint with your actual API endpoint
    const response = await farmsellApi.get(`/postid/${id}/`);
    console.log(response)
  
    return response.data; // Assuming response contains the fetched posts
  } catch (error) {
    throw new Error('Failed to fetch posts');
  }
});


// Create a post slice
const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHtmlContent: (state, action) => {
      state.htmlContent = action.payload;
    },
    setTotalPages:(state,action)=>{
state.totalPages = action.payload
    },
    setTitle: (state, action) => {
      state.title = action.payload
    },
    setDescription: (state, action) => {
      state.description = action.payload
    },
    getPostsId: (state, action) => {
      state.user_posts = action.payload
    },
    setCoverPhotoUrl: (state, action) => {
      state.coverPhotoUrl = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setmetaTitle:(state,action)=>{
state.metaTitle= action.payload
    },
    setmetaDescription:(state,action)=>{
state.metaDescription = action.payload
    },
    setError:(state,action)=>{
      state.postError = action.payload
    },
    setUpdatePost:(state,action)=>{
      state.updatePost = action.payload
    },
    resetState:(state)=>{
      state.singlepost = undefined
      state.coverPhotoUrl =null
      state.title = ''
    }
    ,
    setPostLoading:(state,action)=>{
      state.postLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePostContent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePostContent.fulfilled, (state) => {
        state.loading = false;
        // You can handle any necessary state changes after successfully saving the post
      })
      .addCase(savePostContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPosts.pending, (state) => {
        state.userLoading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.userLoading= false;
        state.user_posts = action.payload
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.userLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPublised.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublised.fulfilled, (state, action) => {
        state.loading = false;
        state.PublisedPosts = action.payload
      })
      .addCase(fetchPublised.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDraft.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDraft.fulfilled, (state, action) => {
        state.loading = false;
        state.DraftPosts = action.payload
      })
      .addCase(fetchDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTrashPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrashPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.TrashPosts = action.payload
      })
      .addCase(fetchTrashPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPostById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostById.fulfilled, (state, action) => {
        state.loading = false;
        state.singlepost = action.payload
      })
      .addCase(fetchPostById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.loading = false;
        const { postId, type } = action.payload;
        if (type === 'draft') {
          state.DraftPosts = state.DraftPosts.filter(post => post.postId !== postId);
        } else if (type === 'published') {
          state.PublisedPosts = state.PublisedPosts.filter(post => post.postId !== postId);
        }
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { setData, setHtmlContent, setTitle, setDescription, getPostsId, setCoverPhotoUrl, setCategories ,setmetaDescription,setmetaTitle,setError,setUpdatePost,setPostLoading,resetState,setTotalPages} = postSlice.actions;
export default postSlice.reducer;
