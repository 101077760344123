import React, { Suspense } from "react";


const Supensing = ({ Component, fallback }) => {
  return (
    <Suspense fallback={fallback }>
      <Component />
    </Suspense>
  );
};

export default Supensing;
