export const categories = [
    "Supply Chain Innovation", "Agile Supply Chains", "Lean Manufacturing", "Blockchain for Logistics", "Sustainable Supply Chains", "Green Procurement", 
    "Circular Economy", "Carbon Footprint Reduction", "Renewable Energy in Agriculture", "Organic Soil Amendments", "Soil Rejuvenation", "Precision Irrigation", 
    "Artificial Intelligence in Agri-Tech", "Machine Learning in Agriculture", "Predictive Analytics in Farming", "Crop Rotation Strategies", "Intercropping", 
    "Vertical Farming", "Urban Agriculture", "Indoor Farming", "Agricultural Robotics", "Farm Drones", "Autonomous Tractors", "Smart Farm Machinery", 
    "Weather Forecasting for Farmers", "Climate Resilience", "Disaster Risk Management in Agriculture", "Agro-Climatic Zones", "Agro-Environmental Monitoring", 
    "Sustainable Food Systems", "Agri-Tourism", "Farm-to-Table", "Locally Sourced Foods", "Organic Markets", "Green Certifications", "Sustainable Sourcing", 
    "Fair Trade Farming", "Environmental Conservation", "Biodynamic Farming", "Composting Techniques", "Permaculture Design", "Biological Pest Control", 
    "Integrated Weed Management", "Ecological Farming", "Farming Innovations", "Agricultural Mechanization", "Plant Health Management", "Plant Disease Control", 
    "Farm Input Management", "Agricultural Advisory Services", "Weather-Based Insurance", "Agri-Insurance", "Climate Insurance", "Farmer Empowerment", 
    "Capacity Building in Agriculture", "Farmer Cooperatives", "Access to Finance for Farmers", "Rural Bank Financing", "Digital Farming Solutions", 
    "ICT for Agriculture", "Big Data in Agriculture", "Geo-Tagging in Farming", "Remote Sensing for Crops", "Agricultural Productivity", "Post-Harvest Loss Reduction", 
    "Agro-Industrial Parks", "Rural Electrification", "Micro-Grid Solutions", "Solar Energy for Farms", "Wind Energy in Agriculture", "Energy Efficiency in Agri-Business", 
    "Rural Connectivity", "Agricultural Value Chains", "Agricultural Transformation", "Sustainable Development Goals (SDGs) in Agriculture", "Impact Investment in Agriculture", 
    "Sustainable Livelihoods", "Climate Change Mitigation in Agriculture", "Food Resilience", "Regenerative Livestock Farming", "Carbon Sequestration", 
    "Greenhouse Gas Emissions Reduction", "Low Carbon Agriculture", "Agri-Business Finance", "Agricultural Equity Financing", "Agribusiness Incubators", 
    "Seed Capital for Farmers", "Social Impact Bonds", "Sustainable Investments", "Impact Finance", "Agri-Innovation Hubs", "Agricultural Venture Funds", 
    "Environmental Impact Assessment", "Sustainable Agriculture Policy", "Agri-Environmental Policy", "Food Sovereignty", "Food Policy Analysis", 
    "Agricultural Subsidies", "Agri-Trade Policy", "Farmer Welfare Schemes", "Agro-Export Financing", "Agricultural Trade Barriers", "Export-Import Compliance", 
    "International Trade Laws", "Tariff Barriers", "Customs Procedures for Agriculture", "Supply Chain Risk Mitigation", "Agri-Supply Chain Transparency", 
    "Value Chain Enhancement", "Farming Cooperatives", "Farmer Market Linkages", "Digital Marketplaces for Agriculture", "Direct-to-Consumer Farming", 
    "Agri-FinTech", "Mobile Money in Agriculture", "Agro-E-commerce Platforms", "Farming as a Service (FaaS)", "Farm Credit Systems", "Agri-Microloans", 
    "Financial Literacy for Farmers", "Risk Sharing in Agriculture", "Crowd-sourced Agricultural Data", "Blockchain for Supply Chain Transparency", 
    "Precision Crop Monitoring", "Sustainable Aquaculture", "Aquaponics", "Urban Fish Farming", "Fish Feed Optimization", "Marine Conservation", 
    "Sustainable Seafood", "Small-Scale Fisheries", "Fishery Management", "Coastal Resource Management", "Blue Economy", "Regenerative Aquaculture", 
    "Biomass Energy", "Biofuel Crops", "Waste-to-Energy Solutions", "Agricultural By-products", "Compostable Packaging", "Renewable Resources", 
    "Bio-Based Materials", "Sustainable Packaging", "Food Waste-to-Energy", "Greenhouse Gas Accounting", "Eco-Efficiency", "Zero Waste Agriculture", 
    "Green Logistics", "Sustainable Transport", "Low-Emission Farming Practices", "Energy Conservation in Agriculture", "Water Recycling in Farms", 
    "Water-Efficient Crops", "Agricultural Land Management", "Smart Irrigation Technologies", "Remote Water Monitoring", "Flood Management in Agriculture", 
    "Agri-Resilience", "Drought-Resistant Crops", "Seed Security", "Climate-Resilient Seeds", "GMO-Free Farming", "Seed Banks", "Biodiversity in Agriculture", 
    "Indigenous Crop Varieties", "Agroecological Intensification", "Resilient Agroecosystems", "Sustainable Land Use", "Land Degradation Neutrality", 
    "Land Use Planning in Agriculture", "Soil Carbon Sequestration", "Agri-Environmental Indicators", "Sustainable Livestock Systems", "Integrated Crop-Livestock Systems", 
    "Organic Livestock Management", "Pasture Management", "Grassland Conservation", "Agro-Silvopastoral Systems", "Livestock Emissions Management", 
    "Animal Welfare in Farming", "Alternative Livestock Feed", "Feed Conversion Efficiency", "Sustainable Animal Husbandry", "Ethical Meat Production", 
    "Heritage Breeds", "Alternative Proteins", "Insect Farming", "Edible Insects", "Plant-Based Proteins", "Meat Alternatives", "Cellular Agriculture", 
    "Lab-Grown Meat", "Sustainable Food Innovation", "Food Technology Startups", "Functional Foods", "Nutraceuticals", "Biofortification", 
    "Fortified Foods", "Smart Food Systems", "Food Traceability", "Food Safety Regulations", "Digital Food Safety Solutions", "Blockchain for Food Safety", 
    "HACCP Implementation", "Food Defense", "Crisis Management in Food Safety", "Global Food Safety Standards", "Organic Certification Standards", 
    "Fair Trade Certification", "Eco-Labeling", "Sustainable Certification", "Traceability Technologies", "Food Labeling Compliance", 
    "Farm-to-School Programs", "Food and Nutrition Security", "Sustainable Food Supply Chains", "Food Access and Equity", "Agricultural Outreach Programs", 
    "Farmer Extension Services", "Community Farming Initiatives", "Urban Food Policy", "Community Gardening", "Food Hubs", "Local Food Markets", 
    "Agricultural Leadership Programs", "Farmer Knowledge Sharing", "Agricultural Forums", "Digital Farming Communities", "Farmers’ Social Networks", 
    "Collaborative Farming Platforms", "Farmer Mentorship Programs", "Agricultural Capacity Building", "Agri-Learning Centers", "Farmer-Led Research", 
    "Participatory Farming Practices", "Community-Supported Agriculture Models", "Food Sovereignty Movements", "Agri-Digital Literacy", "Agri-Youth Empowerment", 
    "Youth in Agriculture", "Women in Agriculture", "Agricultural Gender Equality", "Empowering Female Farmers", "Inclusive Agriculture", 
    "Sustainable Rural Employment", "Agricultural Cooperatives", "Agricultural Collectives", "Farmers' Unions", "Agricultural Advocacy Groups", 
    "Agricultural Lobbying", "Agri-Policy Reforms", "Rural Infrastructure Development", "Rural Employment Schemes", "Rural Economic Diversification", 
    "Agri-Community Development", "Smallholder Farmer Programs", "Food Justice", "Sustainable Farm Practices", "Soil Moisture Monitoring", 
    "Agricultural Drones", "Remote Crop Monitoring", "Farm Sensors", "Agri-Biotechnology", "Crop Genomics", "Plant Tissue Culture", 
    "Biotechnology for Livestock", "Microbial Soil Enhancers", "Bioinformatics in Agriculture", "Data-Driven Agriculture", "Digital Twins in Farming", 
    "Real-Time Farm Data", "Farm Analytics Platforms", "AI-Powered Precision Farming", "Robotics in Agri-Business", "Farm Automation", "IoT in Agriculture", 
    "Smart Farm Networks", "Connected Farm Systems", "Farm Monitoring Apps", "Weather Data Integration for Farms", "Agricultural Data Platforms", 
    "Blockchain for Agri-Contracts", "Smart Contracts in Agriculture", "Agri-Supply Chain Finance", "Crowd-Financing for Farmers", "Farm Risk Assessment Tools", 
    "Agricultural Impact Investing", "Sustainable Farm Finance", "Agricultural Investment Funds", "Green Bonds for Agriculture", "Carbon Credit Trading", 
    "Agri-Carbon Markets", "Sustainable Crop Insurance", "Agricultural Loan Products", "Farm Finance Solutions", "Micro-Insurance for Farmers", 
    "Risk Mitigation in Agriculture", "Resilient Farm Systems", "Disaster-Proof Farming", "Food System Resilience", "Agri-Food System Transformation", 
    "Nutrition-Sensitive Agriculture", "Agri-Nutrition Programs", "Food Systems Governance", "Sustainable Food Policy", "Nutrition for Development", 
    "Urban Food Systems", "Rural-Urban Food Linkages", "Agricultural Economic Development", "Agri-Based Small Businesses", "Agripreneurs", 
    "Youth Agripreneurship", "Female Agripreneurs", "Innovative Agri-Startups", "Agri-Financing Models", "Business Development for Farmers", 
    "Farm Business Planning", "Agricultural Market Systems", "Farmer-Owned Businesses", "Agri-Social Enterprises", "Agri-Cooperative Management", 
    "Agri-Business Development Services", "Agricultural Supply Chain Finance", "Green Finance for Agriculture", "Agri-Financial Inclusion", 
    "Technology for Smallholders", "Digital Tools for Farmers", "Precision Farming for Smallholders", "Agri-Education Initiatives", "Farmer Training Programs", 
    "Online Agri-Courses", "Virtual Agricultural Workshops", "Farmer Field Schools", "Farmer Study Circles", "On-Farm Demonstrations", 
    "Farmer Training Videos", "Agricultural Knowledge Platforms", "Agri-Online Learning", "Farmer Innovation Networks", "Agricultural Research Institutes", 
    "Rural Research and Development", "Smallholder Farm Technologies", "Affordable Farm Technologies", "Innovations for Small Farmers", 
    "Access to Agri-Technology", "Agricultural Extension Technology", "Agri-Digital Inclusion", "Digital Agriculture Advisory Services", 
    "Mobile Farming Solutions", "Agri-Tech Startups for Smallholders", "Last-Mile Agri-Technology Delivery", "Farmer ICT Solutions", 
    "E-Agriculture Solutions", "Digital Marketing for Farmers", "Agri-Digital Payments", "Farm Credit Platforms", "Crowd-Lending for Agriculture", 
    "Farmer Savings Platforms", "Peer-to-Peer Lending for Farmers", "Agri-Savings Programs", "Digital Farmer Wallets", "FinTech for Agriculture", 
    "Agri-Lending Platforms", "Credit Scoring for Farmers", "Farmer Credit History", "Agri-Insurance Platforms", "Digital Crop Insurance", 
    "Mobile Insurance Solutions for Farmers", "Risk Reduction in Agriculture", "Digital Risk Assessment Tools for Farmers", "Climate Risk Insurance", 
    "Farmer Risk Management Tools", "Digital Market Linkages for Farmers", "Agri-Digital Trade Platforms", "Smart Commodity Markets", 
    "Farm-to-Market Solutions", "Digital Marketplaces for Smallholders", "Agri-E-commerce", "Farmer-to-Consumer Platforms", "Online Farming Platforms", 
    "Agri-Blockchain Technology", "Distributed Ledger Technology in Farming", "Blockchain for Agricultural Transactions", "Agri-Smart Contracts", 
    "Blockchain for Traceability in Agri-Supply Chains", "Agri-Credit Risk Analysis", "Agricultural Loan Systems", "Farm Micro-Credit", 
    "Agri-Crowdfunding Platforms", "Mobile Agricultural Payment Systems", "Blockchain for Food Safety", "Agricultural Price Forecasting Tools", 
    "Agri-Price Indexing", "Agri-Market Forecasting Tools", "Data Analytics for Smallholder Farmers", "Farmer Price Negotiation Tools", 
    "Agricultural Price Discovery Platforms", "Commodity Exchange Platforms", "Agri-Futures Trading", "Farm-to-Fork Solutions", 
    "Food Safety Monitoring Platforms", "Smart Packaging Solutions", "Agri-Waste Management Systems", "Food Waste Reduction Platforms", 
    "Agricultural Sustainability Metrics", "Digital Sustainability Reporting", "Carbon Footprint Tools for Farmers", "Sustainability Certification Tools", 
    "Agri-Energy Solutions", "Smart Energy Solutions for Agriculture", "Agri-Environmental Monitoring Tools", "Sustainability Assessment Tools", 
    "Regenerative Agriculture Technologies", "Sustainable Livelihoods Platforms", "Agri-Digital Infrastructure", "Farmer Connectivity Platforms"
  ]
  