// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import UserReducer from '../Slices/User.slice';
import PostSlice from '../Slices/Post.slice';
import fetchpostSlice from '../Slices/Posts.slice'
import LoadindSlice from '../Slices/Loadind.slice';
// Configure Redux store
const Store = configureStore({
  reducer: {
    users: UserReducer,
    posts:PostSlice,
    BlogPosts:fetchpostSlice,
    loading:LoadindSlice
  },
});

export default Store;
