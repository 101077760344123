import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import ImageTool from '@editorjs/image';
import TextAlign from "@canburaks/text-align-editorjs"

import axios from 'axios';
import { farmsellApi } from "../../../../Api/famsellAPi";

const serverDomain = process.env.REACT_APP_SERVER_DOMAIN;
export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,

image: {
      class: ImageTool,
      config: {
       
        uploader: {
          /**
           * Upload file to the server and return an uploaded image data
           * @param file - file selected from the device or pasted by drag-n-drop
           * @return {Promise} - Promise that resolves to an object containing success status and image URL
           */
          uploadByFile(file){
            const formData = new FormData();
            formData.append('file', file);

            return farmsellApi.post('/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              console.log(response.data.paths[0])
              const imageUrl = response.data.paths[0];
              
              return {
                success: 1,
                file: {
                  url: `${serverDomain}${imageUrl}`,
                }
              };
            })
            .catch(error => {
              console.error('Error uploading file:', error);
              
            });
          },

          /**
           * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
           * @param url - pasted image URL
           * @return {Promise} - Promise that resolves to an object containing success status and image URL
           */
          uploadByUrl(url){
            // your ajax request for uploading by URL goes here
          }
        }
      }
    },
 
  raw: Raw,
  header: {
    class: Header,
    inlineToolbar: true,
     // Add alignment tool to header
  },
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  textAlign:TextAlign,
  
}

