import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BlogNav from "../BlogNav";
import OuterNavBar from "../../Pages/OuterNavBar";
import "../UserProfile/user.css";
import { Link } from "react-router-dom";
import { farmsellApi } from "../../Api/famsellAPi";
import Loader from "../../utils/Loader";
import BottonLoader from "../../utils/BottonLoader";
export default function EmailSubmit() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [responsemessage, setmessage] = useState("");
  const [Error, setError] = useState("");
  const [email, setemail] = useState("");
  const handleChange = (e) => {
    setemail(e.target.value);
  };

 
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
     
      setLoading(true);
      try {
        const response = await farmsellApi.post("/forgetPassword/", {
          email,
        });
        console.log(response);
        if (response) {
          setmessage("Please check your email for the  next steps");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        setmessage(error.response?.data.message);
      }
    },
    [email]
  );
  useEffect(()=>{
    if (!email) {
      return setError("Please enter your email!");
    }else{
      return setError("");
    }
  },[email])
  return (
    <div className="BlogWrapper">
      <div>
        <OuterNavBar />
      </div>
      <div className="email-submit-wrapper">
        <div className="input-wrapper">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            placeholder="Please enter your email"
            onChange={handleChange}
          />
        </div>
        <p>{Error}</p>
        <button className="submit-button-email" onClick={handleSubmit}>
          
          {loading ? <BottonLoader/> :'Submit Email'}
          
        </button>
        <div>
          <p>{responsemessage}</p>
        </div>
        <div className="create-option">
          <p style={{ color: "#274C5B" }}>
            No Account?{" "}
            <Link to="/create">
              <span>Create One!</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
