
import React from 'react'
import './Error.css'
export default function ErrorComponent({error}) {
  return (
    <div className='error'>
      <p>{error}</p>
    </div>
  )
}
