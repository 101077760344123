import React from 'react'
import './buttonLoading.css'

export default function BottonLoader() {
  return (
    <div className="button-loader">
      <div className="button-spinner"></div>
    </div>
  )
}
