import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PostHeroCard, PostCard } from "../../Features/Post.Card";
import userPic from "../../Components/Assets/Blog/Ellipse 1.svg";
import { categories } from "../../Components/blogPost/categoryList";
import { useNavigate, useParams } from "react-router";
import "./index.css";
import dayjs from "dayjs";
import { farmsellApi } from "../../Api/famsellAPi";
import { CiSearch } from "react-icons/ci";
import {
  setPostsData,
  setRecentPosts,
} from "../../Components/Slices/Posts.slice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RecentPosts } from "../../Features/postFeatures";
import OuterNavBar from "../OuterNavBar";
import Footer from "../Footer";
import { setLoading } from "../../Components/Slices/Loadind.slice";
import useFetchPosts from "../../Hooks/useFetchPosts";
import Loader from "../../utils/Loader";
import SeoComponent from "../../Features/SeoComponent";
export default function BlogIndex() {
  const dispatch = useDispatch();
  const [post, setPost] = useState([]);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayinput, setDisplayInput] = useState(false);
  const [displayAllCategories, setDisplayAllCategories] = useState(false);
  const navigate = useNavigate();
console.log({
  currentPage
})
  const { fetchPosts } = useFetchPosts("/post/recent", {
    params: {
      currentPage,
    },
  });

  const { fetchData } = useFetchPosts("/posts",currentPage);
  const BlogPosts = useSelector((state) => state.BlogPosts.posts);
  const recentPosts = useSelector((state) => state.BlogPosts.recentPosts);
  const AllPosts = useSelector((state) => state.BlogPosts.AllPosts);
  const loading = useSelector((state) => state.loading.loading);
  const rentLoading = useSelector((state) => state.BlogPosts.rentPostsLoading);
  const postLoading = useSelector((state) => state.BlogPosts.postsLoading);
  const totalPages = useSelector((state) => state.posts.totalPages);

  console.log("ralllpostss", AllPosts);
  const [query, setQuery] = useState("");
  useEffect(() => {
    fetchPosts(currentPage);
    fetchData();
  }, [rentLoading, postLoading, currentPage]);

  useEffect(() => {
    if (AllPosts) {
      const formattedPosts = AllPosts.map((post) => {
        const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
        const parsedCategories = post.categories
          ? JSON.parse(post.categories).categories
          : [];

        return {
          key: post.id,
          data: parsedData,
          description: post.description,
          title: post.title,
          metaTitle: post.metaTitle,
          metadescription: post.metadescription,
          Categories: parsedCategories,
          coverPhoto: post.coverImgUrl,
          profilePhoto: post.user?.UserPic || userPic,
          username: post.user?.name || "Unknown Author",
          Created: dayjs(post.createdAt).format("MMMM DD, YYYY"),
        };
      });
      console.log("allPosts",formattedPosts)
      setPost(formattedPosts);
    }

    if (recentPosts) {
      const formattedPosts = recentPosts.map((post) => {
        const parsedData = post.data ? JSON.parse(post.data)?.rawdata : null;
        const parsedCategories = post.categories
          ? JSON.parse(post.categories).categories
          : [];
        return {
          key: post.id,
          data: parsedData,
          description: post.description,
          title: post.title,
          metaTitle: post.metaTitle,
          metadescription: post.metadescription,
          Categories: parsedCategories,
          coverPhoto: post.coverImgUrl,
          profilePhoto: post.user?.UserPic || userPic,
          username: post.user?.name || "Unknown Author",
          Created: dayjs(post.createdAt).format("MMMM DD, YYYY"),
        };
      });
      setPosts(formattedPosts);
    }
  }, [recentPosts, AllPosts,currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleChangeQuery = (e) => {
    setQuery(e.target.value);
  };
  const filteredPosts = post.filter((post) =>
    post.title.toLowerCase().includes(query.toLowerCase())
  );
  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  return (
    <div className="BlogWrapper">
      <div className="blog-wrapper-posts">
        <OuterNavBar />

        <div className="header-wrapper">
          <div className="categories">
            {/* <select
              value={selectedValue}
              onChange={(e) => {
                const selectedCategory = e.target.value;
                setSelectedValue(selectedCategory);
                handleCategoryClick(selectedCategory); // Trigger navigation
              }}
              style={{ overflow: "hidden", height: "6vh" }}
            >
              {categories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select> */}
            {categories.slice(0, 3).map((category, index) => (
              <p
                key={index} // Using index as the key since the array is just strings
                onClick={() => handleCategoryClick(category)} // Assuming category is a string
                // Conditional class assignment
              >
                {category}
              </p>
            ))}

            <div>
              <p onClick={()=>setDisplayAllCategories(!displayAllCategories)}>All</p>
            </div>
          </div>
          <div className="posts-input-header">
            {displayinput && (
              <input
                type="text"
                className="form-control"
                placeholder="Hi, what would you want to read today?"
                value={query}
                onChange={handleChangeQuery}
              />
            )}
            <button
              className="search-icon"
              onClick={() => setDisplayInput(!displayinput)}
            >
              <CiSearch />
            </button>
          </div>
        </div>
        {displayAllCategories && (
          <div className="categories-all-wrapper">
          <div className="categories-all">
            {categories.slice(0, 8).map((category, index) => (
              <p
                key={index} // Using index as the key since the array is just strings
                onClick={() => handleCategoryClick(category)} // Assuming category is a string
                // Conditional class assignment
              >
                {category}
              </p>
            ))}
          </div>
          <div className="categories-all">
            {categories.slice(8, 16).map((category, index) => (
              <p
                key={index} // Using index as the key since the array is just strings
                onClick={() => handleCategoryClick(category)} // Assuming category is a string
                // Conditional class assignment
              >
                {category}
              </p>
            ))}
          </div>
          <div className="categories-all">
            {categories.slice(16, 24).map((category, index) => (
              <p
                key={index} // Using index as the key since the array is just strings
                onClick={() => handleCategoryClick(category)} // Assuming category is a string
                // Conditional class assignment
              >
                {category}
              </p>
            ))}
          </div>
        </div>
        )}
        <div className="post-hero-wrapper">
          {query.length === 0 && <PostHeroCard post={posts} />}
        </div>
        <div className="grid-container">
          {loading ? (
            <Loader />
          ) : (
            filteredPosts.map((postItem) => (
              <PostCard key={postItem.key} post={postItem} />
            ))
          )}
        </div>
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="page-button"
          >
            &larr;
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`page-button ${currentPage === i + 1 ? "active" : ""}`}
            >
              {i + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="page-button"
          >
            &rarr;
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
