import { farmsellApi } from "../Api/famsellAPi"
import { useDispatch,useSelector } from "react-redux"
import { setLoading } from "../Components/Slices/Loadind.slice"
import { setPostsData,setRecentPosts,setCategories ,setPostDetails,setAllPosts} from "../Components/Slices/Posts.slice";
import { setTotalPages } from "../Components/Slices/Post.slice";
import { useState } from "react";


const useFetchPosts = (endpoint,params={},currentPage)=> {
const dispatch = useDispatch()
const  [pages,setPages] = useState()
const [setBlog] = useState()
const loading = useSelector(state => state.loading)
const fetchPosts = async () => {
    try {
      dispatch(setLoading(true))
      const res = await farmsellApi.get(endpoint, {
        params
      });
        dispatch(setRecentPosts(res.data.posts));
        dispatch(setLoading(false));

    } catch (error) {
      console.log('Error fetching the data', error)
      dispatch(setLoading(false))
    }
  };

   const fetchData = async () => {
    try {
      setLoading(true);
      const res = await farmsellApi.get(endpoint, {params:{
        currentPage
      }});
      console.log("Fetched data ",res.data);
      dispatch(setAllPosts(res.data.posts));
     
     dispatch(setTotalPages(res.data.totalPages))
      dispatch(setLoading(false));
      console.log("All posts ",res.data.posts)
    } catch (error) {
      console.log('Error fetching the data', error);
      dispatch(setLoading(false));
    }
  };
  const fetchCategories = async () => {

    try{
   await farmsellApi.get('/categories')
   .then(res=>{
      dispatch(setCategories(res.data))
    })
    }catch(error) {
      return error.message

    }
  }
  

  return {loading,fetchPosts,fetchData,pages}
}


export default useFetchPosts;