// edjsHTML transforms Editor.js blocks to HTML
import edjsHTML from "editorjs-html";
// This function parses strings (HTML elements) to React components
import parse from "html-react-parser";

// Define custom parsers (for blocks like "simpleImage")
const customParsers = {
  simpleImage: function(block) {
    return `<img src="${block.data.url}" alt="${block.data.caption}" />`;
  },
  // You can add more custom parsers here if needed
};

// Initialize edjsParser with custom parsers
const edjsParser = edjsHTML({ custom: customParsers });

export default function EditorTextParser({ data }) {
  // Array of HTML strings from the parsed Editor.js data
  const html = edjsParser.parse(data);

  // Join the HTML strings and parse them to render as React components
  return <div>{parse(html.join(""))}</div>;
}
